<template>
  <v-list>
    <v-list-item-group>
      <template v-for="(item, index) in activeItems">
        <v-list-item :key="item.dato">
            <v-list-item-content>
              <v-text-field
                v-if="toUpdate && item.id === singleItem.id"
                class="mb-0 pb-0"
                v-model="singleItem.dato"
                dense
                outlined
              />
              <v-list-item-title
                v-else
                v-text="item.dato"
              />
            </v-list-item-content>

            <v-list-item-action>
              <v-row
                v-if="toUpdate && item.id === singleItem.id"
                no-gutters
              >
                <v-col>
                  <v-btn
                    icon
                    color=primary
                    title="Cancelar"
                    @click="cancelInline"
                  >
                    <v-icon color="primary">
                      mdi-cancel
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    icon
                    color=primary
                    title="Guardar cambios"
                    @click="updateInline"
                  >
                    <v-icon color="primary">
                      mdi-content-save-outline
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-else
                no-gutters
              >
                <v-col>
                  <v-btn
                    icon
                    color=primary
                    title="Editar"
                    @click="editInline(item)"
                  >
                    <v-icon color="primary">
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    icon
                    color=primary
                    title="Eliminar"
                    @click="deleteInline(item.id)"
                  >
                    <v-icon color="primary">
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="index < activeItems.length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: {
    activeItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      singleItem: {},
      toUpdate: false,
    }
  },
  methods: {
    editInline(item) {
      this.singleItem = {...item}
      this.toUpdate = true
      //this.$emit('editInline', this.singleItem)
    },
    deleteInline(itemId) {
      this.$emit('deleteInline', itemId)
    },
    updateInline() {
      this.$emit('updateInline', this.singleItem)
      this.cancelInline()
    },
    cancelInline() {
      this.$emit('cancelInline')
      this.singleItem = {}
      this.toUpdate = false
    }

  }
}
</script>

<style>

</style>